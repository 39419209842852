var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "tabCard",
      staticStyle: {
        border: "0px !important",
        "border-radius": "4px !important",
      },
    },
    [
      _c(
        "v-card-title",
        {
          staticStyle: {
            "background-color": "#f7f7fd",
            border: "1px solid #e7e7fa !important",
          },
        },
        [
          _c(
            "v-tabs",
            {
              on: { change: _vm.onChangeTab },
              model: {
                value: _vm.tabSelected,
                callback: function ($$v) {
                  _vm.tabSelected = $$v
                },
                expression: "tabSelected",
              },
            },
            [
              _c("v-tabs-slider", {
                attrs: { color: _vm.isDisabled() ? "#F7F7FD" : "#00FF99" },
              }),
              _c(
                "v-tab",
                { attrs: { href: "#todos", value: "todos", id: "todos" } },
                [
                  _c("div", { staticClass: "tab-text" }, [
                    _vm._v("Visão Geral"),
                  ]),
                ]
              ),
              _c(
                "v-tab",
                {
                  attrs: {
                    href: "#afastados-e-inativos",
                    value: "afastados-e-inativos",
                    id: "afastados-e-inativos",
                  },
                },
                [
                  _c("div", { staticClass: "tab-text" }, [
                    _vm._v("Afastamento e inatividade"),
                  ]),
                ]
              ),
              _vm.hasCedidos
                ? _c(
                    "v-tab",
                    {
                      attrs: {
                        href: "#cedidos",
                        value: "cedidos",
                        id: "cedidos",
                      },
                    },
                    [_c("div", { staticClass: "tab-text" }, [_vm._v("Cessão")])]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.tabSelected,
            callback: function ($$v) {
              _vm.tabSelected = $$v
            },
            expression: "tabSelected",
          },
        },
        [
          _c(
            "v-tab-item",
            { attrs: { value: "todos" } },
            [_c("TableColaboradores")],
            1
          ),
          _c(
            "v-tab-item",
            { attrs: { value: "afastados-e-inativos" } },
            [_c("TabAfastadosEInativos", { ref: "tab_afastados_e_inativos" })],
            1
          ),
          _vm.hasCedidos
            ? _c(
                "v-tab-item",
                { attrs: { value: "cedidos" } },
                [_c("TabCedidos", { ref: "tab_cedidos" })],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }