<template>
  <v-card style="border: 0px !important; border-radius: 4px !important" class="tabCard">
    <v-card-title style="background-color: #f7f7fd; border: 1px solid #e7e7fa !important">
      <v-tabs v-model="tabSelected" @change="onChangeTab">
        <v-tabs-slider :color="isDisabled() ? '#F7F7FD' : '#00FF99'"></v-tabs-slider>
        <v-tab href="#todos" value="todos" id="todos">
          <div class="tab-text">Visão Geral</div>
        </v-tab>
        <v-tab href="#afastados-e-inativos" value="afastados-e-inativos" id="afastados-e-inativos">
          <div class="tab-text">Afastamento e inatividade</div>
        </v-tab>
        <v-tab href="#cedidos" value="cedidos" id="cedidos" v-if="hasCedidos">
          <div class="tab-text">Cessão</div>
        </v-tab>
      </v-tabs>
    </v-card-title>
    <v-tabs-items v-model="tabSelected">
      <v-tab-item :value="'todos'">
        <TableColaboradores />
      </v-tab-item>
      <v-tab-item :value="'afastados-e-inativos'">
        <TabAfastadosEInativos ref="tab_afastados_e_inativos" />
      </v-tab-item>
      <v-tab-item :value="'cedidos'" v-if="hasCedidos">
        <TabCedidos ref="tab_cedidos" />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TabsColab',
  // props: ['tab'],
  components: {
    TabCedidos: () => import('../Cedidos.vue'),
    TabAfastadosEInativos: () => import('../AfastadosEInativos.vue'),
    TableColaboradores: () => import('./TableColaboradoresE-Gov.vue'),
  },

  data() {
    return {
      funcTitle: process.env.VUE_APP_TAGFUNC,
    };
  },
  computed: {
    ...mapGetters({
      getTab: 'esocial/esocialMain/getTab',
      title: 'esocial/esocialMain/getTitle',
      roles: 'user/roles',
      userInfo: 'user/userInfo',
      hasMenuCedidos: 'user/hasMenuCedido',
      selectedCompetency: 'wss/getCompetencyInSelected',
    }),
    tabSelected: {
      get: function () {
        return this.getTab;
      },
      set: function (value) {
        this.setTab(value);
        if (value === 'cedidos') {
          this.setTitle('Cessão');
        } else if (value === 'afastados-e-inativos') {
          this.setTitle('Afastados e inativos');
        } else if (value === 'todos') {
          this.setTitle('Todos');
        }
      },
    },
    hasCedidos() {
      let roles = this.roles;
      let hasRole = roles.find((element) => element === 'ROLE_ADMIN');
      return hasRole || this.hasMenuCedidos;
    },
  },

  watch: {
    // tab: 'getParams',
  },

  created() {},

  methods: {
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    // updateScreen() {
    //   this.init(this.userInfo.id);
    // },
    // ...mapActions('wss', ['resetPagination']),
    ...mapActions('esocial/esocialMain', ['setTab', 'setTitle']),
    ...mapActions('esocial/esocialCedidos', ['init']),
    isDisabled() {
      return this.collaboratorsSize < 1 || (this.selectedCompetency && this.selectedCompetency.length < 1);
    },
    onChangeTab() {
      this.closeFilterDropdown('tab_cedidos');
      this.closeFilterDropdown('tab_afastados_e_inativos');
    },
    closeFilterDropdown(tab_ref) {
      if (![undefined, null].includes(this.$refs[tab_ref])) {
        let tabRef = this.$refs[tab_ref];
        if (![undefined, null].includes(tabRef.$refs['filter'])) {
          let filterRef = tabRef.$refs['filter'];
          filterRef.closeFilterDialog();
        }
      }
    },
  },
};
</script>
<style scoped>
::v-deep .v-data-table-header {
  text-align: start;
  background-color: #e7e7fa;
  cursor: default;
}
::v-deep .v-data-table-header tr th span {
  cursor: pointer !important;
}
.v-card__title {
  padding: 0px !important;
}
.tabCard {
  padding: 0 !important;
}

::v-deep .v-slide-group__content.v-tabs-bar__content {
  background: #f7f7fd;
}
.v-tab.v-tab--disabled .tab-text {
  color: #8686a2 !important;
  background-color: #e7e7fa !important;
  font-family: Roboto !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  letter-spacing: 0.01em !important;
  text-align: left !important;
}
.v-tab.v-tab--disabled .tab-text {
  color: #8686a2 !important;
  background-color: #e7e7fa !important;
  font-family: Roboto !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  letter-spacing: 0.01em !important;
  text-align: left !important;
}
</style>
